.slick-next:before {
  font-size: 25px;
  color: #274832;
}

.slick-prev:before {
  font-size: 25px;
  color: #274832;
}

.slick-slide {
  text-align: center;
}

.sheet-ImageCarousel .slick-prev {
  left: -30px;
}
.sheet-ImageCarousel .slick-next {
  right: -30px;
}

.modal-carousel .slick-list {
  max-width: 70vh;
  margin: 0 auto;
}
.modal-carousel .slick-prev {
  left: -10px;
}
.modal-carousel .slick-next {
  right: -10px;
}

.event-description a {
  color: blue;
  text-decoration: underline;
}
