/* .map {
    height: 30vh !important;
    width: 80vw !important;
} */

.gm-style-iw-c{
    padding-bottom: 10px !important;
    padding-right: 10px !important;

}
.gm-style-iw-d{
    overflow: hidden !important;
}

.gm-ui-hover-effect:focus{
    outline: none ;
}
