@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Josefin+Sans:wght@400;500;700&family=Sunflower:wght@300;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .main-container {
    @apply max-w-7xl mx-auto px-2 lg:px-8;
  }
  .mainBtn {
    @apply text-white bg-lightGreen  text-base rounded-xl md:rounded-2xl px-8 py-3 md:py-4 font-Sunflower  scaleHoverEffect hover:bg-white hover:text-lightGreen;
  }
  .para {
    @apply sm:text-lg md:text-2xl leading-loose  lg:leading-normal font-inter  lg:text-4xl;
  }
  .sub-para {
    @apply text-base lg:text-2xl leading-loose lg:leading-relaxed font-inter;
  }
  .greyBtn {
    @apply rounded-3xl bg-lightblue px-12 py-6 font-medium font-Sunflower text-3xl md:text-5xl text-lightGreen scaleHoverEffect;
  }
  

  .heading {
    @apply text-lightGreen font-Sunflower  font-bold text-center  text-4xl md:text-6xl ;
  }

  .sub-heading{
    @apply font-Sunflower text-lightGreen  font-bold text-2xl  md:text-4xl;
  }
  .supportBtn {
    @apply font-bold text-white bg-lightGrey rounded-2xl sm:rounded-[40px] font-Sunflower  sm:text-xl lg:text-3xl px-10 lg:px-14  leading-tight py-2 sm:py-4 lg:py-4 scaleHoverEffect hover:bg-lightGreen;
  }

  .scaleHoverEffect {
    @apply shadow-lg hover:scale-95 hover:drop-shadow-3xl transition duration-300;
  }
 

  .carouselBtn {
    @apply text-lightGreen bg-white  text-base rounded-xl md:rounded-2xl lg:px-3 xl:px-8 py-2 px-4 lg:py-4 font-Sunflower;
  }

}
